.display-1 {
    font-size: 4.75rem;
    line-height: 5rem;
}

.display-3 {
    font-size: 3.25rem;
    font-weight: 500;
    line-height: 3.5rem;
}

.display-5 {
    line-height: 3.25rem;
    @include md {
        line-height: 2.5rem;
    }
}

.lead {
    font-size: 1.5rem !important;
    line-height: 2rem !important;

}

@media (min-width: 768px) {
    .container-md {
        max-width: calc(100vw - 1rem);
    }
}