footer {
    @include md {
        margin-top: 50px;
    }
    .col-sm-2 {
        @include md {
            width: 33%;
        }

    }
    .col-sm-3 {
        @include md {
            width: 66%;
        }
    }

    img {
        width: 45%;
        @include md {
            width: 30%;
        }
    }
    p {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}