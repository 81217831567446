html,
body {
    background-color: #FEF6E6;
    color: #FEF6E6;
    min-height: 100vh;
}

body {
    display: flex;
    flex-direction: column;
    position: relative;
}

main {
    flex: 1 0 auto;
}

section,
header,
footer,
svg {
    position: relative;
}

section,
header,
footer,
svg,
.midway {
    @include md {
        max-width: 100vw;
    }
}

section:not(:first-child) {
    margin: 0 0;

}

// MT CSS 

@font-face {
    font-family: "Bedini";
    src: url("../static/fonts/bedinibold.ttf") format("opentype");
}

@font-face {
    font-family: "Roboto-Bold";
    src: url("../static/fonts/Roboto-Bold.ttf") format("opentype");
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("../static/fonts/Roboto-Regular.ttf") format("opentype");
}

body {

    font-family: "Roboto-Regular";
    color: #FEF6E6;
}

h1 {
    font-family: "Bedini";
    font-size: 40px;
    color: #965055;
    text-shadow: 4px -1px 0px #f9b249;
}

h2 {

    font-family: "Bedini";
    font-size: 36px;
    color: #FEF6E6;
    /*text-shadow: 4px -1px 0px #f9b249;*/
    letter-spacing: .2rem;
}

h4 {

    font-family: "Roboto-Bold";
    color: #7DCAEF;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: .1rem;
}

a {
    color: #FA928B;
    text-decoration: underline;
    font-family: "Roboto-Bold";
    font-weight: bold;

}

a:hover {
    color: #7EAEC5;


}

.noHover:hover {
    text-decoration: none !important;
    background-color: transparent !important;
}

p {

    font-family: "Roboto-Regular";
    font-size: 1.5rem !important;
    color: #FEF6E6;
    ;
    font-weight: normal;

}

ul {
    margin-top: 1.5em;
}

li {

    font-family: "Roboto-Regular";
    font-size: 1.3rem !important;
    color: #173049;
    font-weight: normal;
}

li.completed {

    text-decoration: line-through;
    color: #c4c4c4;
}


strong {

    font-family: "Roboto-Bold";
    font-weight: bold;
}

#coin {
    background-color: #FEF6E6;
}

#tokenomics {
    background-color: #FEF6E6;
}

#roadmap {
    padding-bottom: 2em;
    padding-top: 4em;
    background-color: #FEF6E6;
}

.footer-width {
    width: 100%;
}

.pink {
    color: #FA928B;
}

.hollow {
    -webkit-text-stroke: 1px #F27971;
    color: transparent;
}

.blue-primary {

    color: #7EAEC5;
}

.blue {
    color: #7EAEC5;
}

.blue-link {
    color: #1A99D5;
    text-decoration: none;
    margin-right: 1rem;
    font-size: 20px;
    font-family: "Roboto-Bold";
}

.blue-link:hover {
    color: #F27971;
    text-decoration: none;

}


.blue_box {
    background-color: #7EAEC5;
    z-index: 2;
    width: 50%;
    -webkit-box-shadow: 20px 20px 0px 0px #FA928B;
    box-shadow: 20px 20px 0px 0px #FA928B;
    z-index: 20;
}

.white {
    color: #FEF6E6;
    ;
}

.right {
    float: right;
}

.yellow {
    color: #F9B249;
}

.purple-btn {
    font-family: "OPTIEdgarBold-Extended";
    font-size: 13px;
    background-color: #3A3B8B;
    border-radius: 4px;
    color: #FEF6E6;
    ;
    padding: 8px 25px;
    -webkit-box-shadow: 2px 2px 0px 0px #FFFFFF;
    box-shadow: 2px 2px 0px 0px #FFFFFF;
    margin-right: 34px;
    display: inline-block;
    border: 1px #c4c4c4 solid;
    text-decoration: none;

}

.purple-btn:hover {

    background-color: #F27971;
    border-radius: 4px;
    color: #3A3B8B;
    -webkit-box-shadow: 2px 2px 0px 0px #FFFFFF;
    box-shadow: 2px 2px 0px 0px #FFFFFF;
    text-decoration: none;

}

.purple-btn.padd-bttm {
    margin-bottom: 1.5em;

}

.pink-btn {
    font-family: "Roboto-Bold";
    font-size: 20px;
    background-color: #FA928B;
    border-radius: 4px;
    color: #FEF6E6;
    padding: 8px 25px;
    -webkit-box-shadow: 2px 2px 0px 0px #ffffff;
    box-shadow: 2px 2px 0px 0px #ffffff;
    margin-right: 34px;
    display: inline-block;
    text-decoration: none;

}

.pink-btn:hover {

    background-color: #7EAEC5;

    color: #FEF6E6;
    text-decoration: none;

}

.pink-btn.padd-bttm {
    margin-bottom: 1.5em;

}

.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    -webkit-box-shadow: 2px 2px 0px 0px #FFFFFF;
    box-shadow: 2px 2px 0px 0px #FFFFFF;
}

.btn-primary {
    background-color: unset;
    border-color: unset;
}

#twitter-btn {

    margin-right: 34px;
}

#buy-btn {
    font-family: "Bedini";
    font-size: 24px;
    text-decoration: none;
    color: #F27971;
    display: inline-block;
    letter-spacing: .2rem;

}


#buy-btn:hover {
    color: #7EAEC5;

}

#mint-btn {
    font-family: "Bedini";
    font-size: 24px;
    text-decoration: none;
    color: #F27971;
    display: inline-block;
    letter-spacing: .2rem;

}


#mint-btn:hover {
    color: #7EAEC5;

}

#twitter-btn {
    margin-right: 10px;
    background: transparent;
    border: none;
    font-size: 25px;
    color: #1A99D5;
}

#twitter-btn:hover {
    color: #F27971;
}

.section-padd {

    margin-top: 3em;
}

.circles {
    z-index: 1;
}

.ellipse_16 {
    position: absolute;
    left: -10%;
    bottom: 10%;
}

.ellipse_15 {
    position: absolute;
    right: 1%;

}

.ellipse_3 {
    position: absolute;
    right: 5%;
    top: 15%;
    z-index: 1;

}

.ellipse_4 {
    position: absolute;
    right: 5%;
    top: 25%;
    z-index: 1;
}

.ellipse_6 {
    position: absolute;
    right: 20%;
    z-index: 1;

}

.ellipse_7 {
    position: absolute;
    right: 20%;
    z-index: 1;
}

.ellipse_8 {
    position: absolute;
    right: 22%;
    bottom: 32%;
    z-index: 1;
}



.outer-1 {

    width: 60vw;
    height: 50vw;
    max-width: 800px;
    max-height: 700px;
    position: relative;
    background-color: #7EAEC5;
    width: 50%;
    -webkit-box-shadow: 20px 20px 0px 0px #FA928B;
    box-shadow: 20px 20px 0px 0px #FA928B;
    margin: 0 auto;
    z-index: 20;
}

.inner-1 {

    top: 5%;
    left: 10%;
    width: 80%;
    height: 80%;
    position: absolute;
    border-radius: 50%;

}

.maindiv {
    position: relative;
    margin-top: 10px;

}

#whitepaper-btn {
    display: inline-block;
    width: 290px;
    margin: 0 auto;
    margin-top: 2em;

}


#auditbuttons {
    margin: 0 auto;
    text-align: center;
    margin-top: 2em;
}

#auditbuttonOne {
    display: inline-block;
    width: 190px;
    margin: 0 auto;
    margin-top: .5rem;
}

#auditbuttonTwo {
    display: inline-block;
    width: 190px;
    margin: 0 auto;
    margin-top: .5rem;
}

#auditbuttonThree {
    display: inline-block;
    width: 220px;
    margin: 0 auto;
    margin-top: .5rem;
}

#auditbuttonFour {
    display: inline-block;
    width: 240px;
    margin: 0 auto;
    margin-top: .5rem;
}


.maindiv h2 {
    margin-top: .5em;
    margin-bottom: 1em;
    font-family: "Roboto-Bold";
    font-size: 36px;
    color: #FEF6E6;
    letter-spacing: .2rem;
}

.maindiv h5 {

    font-family: "Roboto-Bold";
    margin-top: .8em;
    color: #FEF6E6;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: .2rem;

}

.maindiv p {

    color: #FEF6E6;
    letter-spacing: .2rem;
    font-family: "Roboto-Regular";
    font-size: 12px;
    font-weight: normal;

}


h2.orange {

    font-family: "Roboto-Bold";
    font-size: 36px;
    color: #f6b34d;
    text-shadow: 4px -1px 0px #18314a;
    letter-spacing: .2rem;
}

footer p {
    color: #7EAEC5;
    font-size: 14px !important;
    letter-spacing: .2rem;
}


footer a {
    text-decoration: none;
    font-size: 14px !important;
    letter-spacing: .1rem;
    color: #7EAEC5;
}

footer a:hover {
    text-decoration: none;
    color: #FA928B;
}

.outer-2 {
    width: 90vw;
    height: 32vh;
    max-width: 1000px;
    max-height: 400px;
    position: relative;
    background-color: #7EAEC5;
    width: 100%;
    -webkit-box-shadow: 20px 20px 0px 0px #FA928B;
    box-shadow: 20px 20px 0px 0px #FA928B;
    margin: 0 auto;
}

.outer-3 {
    width: 90vw;
    height: 32vh;
    max-width: 1000px;
    max-height: 400px;
    position: relative;
    background-color: #7EAEC5;
    width: 50%;
    -webkit-box-shadow: 20px 20px 0px 0px #FA928B;
    box-shadow: 20px 20px 0px 0px #FA928B;
    margin: 0 auto;
}

.inner-2 {
    top: 5%;
    left: 5%;
    width: 90%;
    height: 80%;
    position: absolute;
    border-radius: 50%;
}

#listings .pink-btn {

    margin-top: 1em !important;
    margin-right: 1em !important;
}

#listings .pink-btn:hover {

    background-color: #F85C50;
}

.zindex20 {

    z-index: 20;
}

#listings .maindiv h2 {
    margin-bottom: 0;
}

#tokenomics a {

    color: #ffffff;
}

#tokenomics a:hover {

    color: #FA928B;
}

a.noHover:hover {
    color: #FA928B;
}

@media only screen and (max-width: 1157px) {

    .maindiv h2 {

        font-size: 28px;
        letter-spacing: .1rem;
    }

    .circles {
        display: none;
    }

    h2 {
        font-size: 28px;

    }

    .maindiv h5 {

        font-size: 18px;
        letter-spacing: 0.1rem;
        margin: 0;
    }

    .maindiv p.lead {

        font-size: 18px !important;
        line-height: 1.3rem !important;

    }

}

#buy-btn2 {

    font-family: "Bedini";
    font-size: 20px;
    color: #F27971;
    text-decoration: none;
    margin: 0;
    display: none;
    letter-spacing: 0rem;
}

#buy-btn2:hover {

    color: #7EAEC5;
}

@media only screen and (max-width: 800px) {

    .outer-1 {

        width: 90%;
        height: 80vw
    }

    .outer-2 {

        width: 90%;
        height: 800px;
        max-height: 800px;
    }

    .outer-3 {

        width: 90%;

    }

    #donations a.pink-btn {

        font-size: 12px;
    }

    #audit .outer-1 {

        width: 92vw;
        height: 92vw;
        max-width: 600px;
        max-height: 600px;
        position: relative;
        margin: 0 auto;
    }

    .blue_box {
        width: 100%;

    }

    #twitter-btn {

        margin-right: 0;
        font-size: 18px;
    }

    .purple-btn {
        font-family: "Bedini";
        font-size: 14px;
        padding: 4px 15px;
        margin-right: 8px;
    }

    .top-nav>.purple-btn {
        font-family: "Bedini";
        font-size: 11px;
        padding: 4px 15px;
        margin-right: 8px;
    }

    #buy-btn {
        font-family: "Bedini";
        font-size: 14px;
        text-decoration: none;
        text-shadow: 4px -1px 0px #131B76;
        margin: 0;
        display: inline-block;
        letter-spacing: 0rem;
    }

    #mint-btn {
        font-size: 20px;

    }

    .top-nav {
        padding-bottom: 2em;
    }

    h2,
    h2.orange {
        font-size: 26px;
    }

    .maindiv h2 {

        font-size: 18px;
        letter-spacing: .1rem;
    }

    p.lead {
        font-size: 1.3rem !important;
        line-height: 1.3rem !important;
    }

    .maindiv h5 {

        font-size: 17px;
        letter-spacing: 0.1rem;
        margin: 0;
        margin-bottom: 4px;
        margin-top: 4px;
    }

    .maindiv p.lead {

        font-size: 14px !important;
        margin: 0 !important;


    }

    #buy-btn2 {

        display: inline-block;
    }

    #buy-btn {

        display: none;
    }

    .blue-link {

        font-size: 17px;

    }

    p.lead {
        font-size: 1.3rem !important;
        line-height: 2rem !important;
    }

    .pink-btn {
        font-size: 16px;
    }

}