html, body, input {
    font-family: Degular,
    Arial,
    Helvetica,
    sans-serif;
}

html {
    @include md {
        font-size: 0.8em;
    }

    ::-moz-selection {
        /* Code for Firefox */
        color: black;
        background: #feff00;
    }

    ::selection {
        color: black;
        background: #feff00;
    }

    img, video, svg {
        ::-moz-selection {
            /* Code for Firefox */
            color: transparent;
            background: transparent;
        }

        ::selection {
            color: transparent;
            background: transparent;
        }
    }
}

p {
    font-size: 1.5rem;
    line-height: 2rem;
    // @include md {
    //     font-size: 1.2rem;
    //     line-height: 1.6rem;
    // }
}

