// Small tablets and large smartphones (landscape view)
$screen-sm-max: 576px;
// Small tablets (portrait view)
$screen-md-max: 768px;

// Tablets and small desktops
$screen-lg-max: 1368px;


// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

@mixin min-md {
    @media (min-width: #{$screen-md-max}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (min-width: #{$screen-lg-max + 1}) {
        @content;
    }
}

@mixin short {
    @media (max-height: 910px) and (min-width: 1024px) {
        @content;
    }
}

@media screen and (min-width: 991px) {
    .mobile-break {
        display: none;
    }
}