.backgrounds {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    // height: 100%;
    // will-change: filter;
    overflow: hidden;


    .ocean-ceiling > *,
    .ocean-floor {
        position: absolute;
        top: 0;
        left: 50%;
        width: 110%;
        height: 100%;
        transform: translateX(-50%);
        background-size: contain;
        background-repeat: no-repeat;
        // filter: url(#turbulence);


    }

    .ocean {
        &-ceiling {
            &-bg,
            &-left,
            &-right,
            &-bottom {
                background-position: center top;
                background-repeat: no-repeat;
            }
            &-bg {
                // background-image: url('../static/images/ocean-ceiling-bg.png');
                // background-image: url('../static/images/bg-ocean-ceiling.jpg');
            }
            &-left {
                // background-image: url('../static/images/ocean-ceiling-left.png');
            }
            &-right {
                // background-image: url('../static/images/ocean-ceiling-right.png');
            }
            &-bottom {
                // background-image: url('../static/images/ocean-ceiling-bottom.png');
                &:after {
                    content: '';
                    width: 100%;
                    height: 20%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-image: linear-gradient(0deg, black, rgba(0,0,0,0.5));
                }
            }

        }

        &-floor {
            // background-image: url('../static/images/bg-ocean-floor.jpg');
            background-position: center bottom;
        }
    }
}